import DomainContextParams from '@/utils/types/DomainContextParams';

export const SESSION_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment sessionGenericPageHeaderFragment on Session {
    __typename
    id
    uid
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    videoEnabledStartTime
    videoEnabledStartTimestamp
    videoEnabledEndTime
    videoEnabledEndTimestamp
    showLiveChat
    showQNA
    showPolls
    videoType
    videoEnabled
    videoLiveEnabled
    videoLiveEndpoint
    videoLiveEmbedEndpoint
    videoArchiveEnabled
    videoArchiveEndpoint
    videoArchiveEmbedEndpoint
    moderators: _moderators {
      uid
      firstName
      lastName
      jobTitle
      employerName
      pictureFileResource {
        schemaCode
        path
      }
    }
    parentSession {
      uid
    }
    bannerFileResource {
      schemaCode
      path
    }
    categoriesInContext(context: "${DomainContextParams.session}") {
      uid
      name
    }
    _actions(actions: ["CAN_VIEW_LIVE_VIDEO"]) {
       value
       key
    }
    _isRecommendedForMe
    _bookmarkCount
    featured
    _isInAgenda(myUid: "%authUser%")
  }
`;
